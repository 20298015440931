export const DATA_METRIC_KEY = [
  {
    channeL: 'Total',
    net_revenue: '100,000',
    discount_ratio: '100',
    gross_profit: '100',
    gross_profit_ratio: '100',
    total_customer: '100',
    new_customer: '30',
    return_customer: '100',
    order: '100',
    aov: '10,000',
    avg_item: '10',
  },
  {
    channeL: 'Website',
    net_revenue: '100,000',
    discount_ratio: '100',
    gross_profit: '100',
    gross_profit_ratio: '100',
    total_customer: '100',
    new_customer: '30',
    return_customer: '100',
    order: '100',
    aov: '10,000',
    avg_item: '10',
  },
  {
    channeL: 'Chat',
    net_revenue: '100,000',
    discount_ratio: '100',
    gross_profit: '100',
    gross_profit_ratio: '100',
    total_customer: '100',
    new_customer: '30',
    return_customer: '100',
    order: '100',
    aov: '10,000',
    avg_item: '10',
  },
  {
    channeL: 'App',
    net_revenue: '100,000',
    discount_ratio: '100',
    gross_profit: '100',
    gross_profit_ratio: '100',
    total_customer: '100',
    new_customer: '30',
    return_customer: '100',
    order: '100',
    aov: '10,000',
    avg_item: '10',
  },
]

export const DATA_LINE_CHART = {
  labels: [
    'Aug 1',
    'Aug 2',
    'Aug 3',
    'Aug 4',
    'Aug 5',
    'Aug 6',
    'Aug 7',
    'Aug 8',
    'Aug 9',
    'Aug 10',
  ],
  // data: [
  //   {
  //     label: 'Store 1',
  //     values: [35, 15, 28, 100, 35, 15, 28, 100],
  //   },
  //   {
  //     label: 'Web 1',
  //     values: [100, 47, 12, 123, 35, 15, 28, 100],
  //   },
  //   {
  //     label: 'App',
  //     values: [50, 89, 100, 123, 35, 15, 28, 100],
  //   },
  //   {
  //     label: 'App 1',
  //     values: [20, 19, 60, 23, 115, 300, 18, 60],
  //   },
  //   {
  //     label: 'App 1',
  //     values: [20, 19, 60, 23, 115, 300, 18, 60],
  //   },
  //   {
  //     label: 'App 1',
  //     values: [20, 19, 60, 23, 115, 300, 18, 60],
  //   },
  //   {
  //     label: 'App 1',
  //     values: [20, 19, 60, 23, 115, 300, 18, 60],
  //   },
  //   {
  //     label: 'App 1',
  //     values: [20, 19, 60, 23, 115, 300, 18, 60],
  //   },
  // ],
  data: [
    {
      label: 'Advertising',
      values: [
        700, 1, 1, 1, 1, 1, 1, 2, 34, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0,
      ],
    },
    {
      label: 'Contact Center',
      values: [
        1000, 1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 2, 1, 1, 3, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0,
      ],
    },
    {
      label: 'Mobile App',
      values: [
        1500, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 3, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0,
        0,
      ],
    },
    {
      label: 'Physical Store',
      values: [
        3836, 4059, 4347, 3373, 3726, 4242, 3798, 3908, 4144, 4666, 3920, 4273, 3831, 3857, 4042,
        4135, 4533, 4067, 3770, 3916, 3810, 3386, 3974, 4406, 3963, 3977, 3669, 4087, 4135, 4058,
        4179,
      ],
    },
    {
      label: 'Social Page',
      values: [
        4000, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 1, 2, 0, 0, 1, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0,
      ],
    },
    {
      label: 'Total',
      values: [
        3849, 4068, 4359, 3377, 3734, 4253, 3802, 3913, 4179, 4672, 3930, 4283, 3839, 3865, 4047,
        4143, 4546, 4075, 3778, 3925, 3824, 3393, 3985, 4413, 3966, 3982, 3678, 4090, 4135, 4064,
        4185,
      ],
    },
    {
      label: 'Website',
      values: [
        8000, 7, 10, 3, 7, 9, 3, 1, 5, 4, 8, 8, 7, 6, 8, 8, 11, 7, 7, 6, 13, 6, 12, 7, 3, 6, 10, 3,
        2, 7, 7,
      ],
    },
  ],
  latency: '2022-07-25',
}
export const COLOR_LINE_CHART = [
  '#1890FF',
  '#91D5FF',
  '#B37FEB',
  '#FF9C6E',
  '#FFC069',
  '#D3F261',
  '#531DAB',
  '#08979C',
  '#64748B',
  '#597EF7',
]
export const FormatDataLineChart = (data, colors = COLOR_LINE_CHART) => {
  var lineLabels: any[] = []
  data.data?.map((item, index) => lineLabels.push({ title: item.label, color: colors[index] }))
  const dataSource = data.labels?.reduce((previousValue, currentValue, index) => {
    const formatValueRow = data.data?.reduce((pre, curr) => {
      return { ...pre, [curr?.label]: curr?.values[index] }
    }, {})
    return [...previousValue, { name: currentValue, ...formatValueRow }]
  }, [])
  return { dataSource, lineLabels }
}
