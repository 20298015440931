import React, { useEffect, useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useHistory, useParams } from 'react-router-dom'
import { message } from 'antd'
import { useInView } from 'react-intersection-observer'

import { getAudienceSegment } from 'Services/customer-segments'
import FiltersHeader from '../Components/FiltersHeader'
import Body from './Body'

const Overview = () => {
  const { id } = useParams()
  const history = useHistory()

  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  const [segmentName, setSegmentName] = useState('')

  useEffect(() => {
    const handleSegmentName = async () => {
      try {
        const resp = await getAudienceSegment(id)
        setSegmentName(resp.data.name)
      } catch (err: any) {
        message.error('Something went wrong')
      }
    }
    if (id) {
      setBreadCrumb([
        {
          title: 'Audience Analytics',
          path: `/analytics/customers?${history.location.search}`,
        },
        { title: 'Segment 360', path: `/analytics/customer360/${id}` },
      ])
    }
    handleSegmentName()
  }, [setBreadCrumb, id, history.location.search])

  if (!id) return null

  return (
    <div className="AnalyticsWrapper px-8 py-7 w-full">
      <span className="text-3xl font-semibold">{segmentName}</span>
      <div>
        <FiltersHeader hideDimensions />
      </div>

      <Body />
    </div>
  )
}

export default Overview
