import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'

import { getSegment360KeyMetrics } from 'Services/Analytics/segment-analytics'
import LoadingIcon from 'Components/LoadingIcon'
import KeyMetricsTemplate from '../../Components/KeyMetrics.Template'
import PivotTableTemplate from '../../Components/PivotTable.Template'

import ChartRow1 from './ChartRow_1'
import ChartRow2 from './ChartRow_2'
import ChartRow3 from './ChartRow_3'
import ChartRow4 from './ChartRow_4'
import ChartRow5 from './ChartRow_5'
import ChartRow6 from './ChartRow_6'

import PivotRow1 from './PivotRow_1'
import PivotRow2 from './PivotRow_2'

import './index.scss'

const Body = () => {
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })
  const { ref: ref10, inView: ref10InView } = useInView({ triggerOnce: true })

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 200)
  }, [history.location.search])

  if (isResetting)
    return (
      <div className="w-full flex justify-center items-start" style={{ height: 500 }}>
        <LoadingIcon style={{ width: 40 }} />
      </div>
    )

  return (
    <div className="my-4">
      <div style={{ minHeight: 200 }} ref={ref1} className="bg-white p-4 segment-360-metrics">
        <div className="mb-4 font-semibold">Key Metrics</div>
        {ref1InView && <KeyMetricsTemplate handleDataFunction={getSegment360KeyMetrics} />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref2} className="grid grid-cols-3 gap-4 mt-4">
        {ref2InView && <ChartRow1 />}
      </div>
      <div style={{ minHeight: 600 }} ref={ref3} className="bg-white mt-4 grid grid-cols-3">
        {ref3InView && <ChartRow2 />}
      </div>
      <div style={{ minHeight: 600 }} ref={ref4} className="bg-white mt-4 grid grid-cols-3">
        {ref4InView && <ChartRow3 />}
      </div>
      <div style={{ minHeight: 600 }} ref={ref5} className="bg-white mt-4 grid grid-cols-3">
        {ref5InView && <ChartRow4 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref6} className="bg-white mt-4 grid grid-cols-3">
        {ref6InView && <ChartRow5 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref7} className="mt-4 gap-4 grid grid-cols-3">
        {ref7InView && <ChartRow6 />}
      </div>
      <div ref={ref8} style={{ minHeight: 700 }} className="bg-white p-4 mt-4">
        {ref8InView && <PivotRow1 />}
      </div>

      {/* 
      <div ref={ref9} style={{ minHeight: 700 }} className="bg-white p-4 mt-4">
        {ref9InView && <PivotRow2 />}
      </div> */}
      {/* Temporary hide */}
      {/* <div ref={ref10} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Customize Section</div>
        {ref10InView && <PivotTableTemplate />}
      </div> */}
    </div>
  )
}

export default Body
