import React from 'react'

import MultiDoughnutChartTemplate from '../../Components/MultiDoughnutChart.Template'
import BarChartTemplate from '../../Components/BarChart.Template'

import { getSegment360Charts } from 'Services/Analytics/segment-analytics'

const ChartRow1 = () => {
  return (
    <>
      <div className="bg-white p-4 col-span-2">
        <div className="mb-4 font-semibold">New vs Return</div>
        <div className="flex h-full gap-4">
          <div style={{ minHeight: 300 }} className="w-1/2 overflow-scroll">
            <MultiDoughnutChartTemplate
              chartCode="new_return_doughnut"
              handleDataFunction={getSegment360Charts}
            />
          </div>
          <div style={{ minHeight: 300 }} className="w-1/2 overflow-scroll">
            <BarChartTemplate
              chartCode="new_return_bar"
              handleDataFunction={getSegment360Charts}
              isStacked
            />
          </div>
        </div>
      </div>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold">New vs Return</div>
        <div style={{ minHeight: 300 }} className="w-full">
          <MultiDoughnutChartTemplate
            chartCode="membership"
            handleDataFunction={getSegment360Charts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow1
