import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Skeleton } from 'antd'
import axios from 'axios'

import { FiltersType } from 'AnalyticsTypes'
import TableKeyMetric from './TableKeyMetric'
import { DATA_METRIC_KEY } from 'Pages/Analytics/SaleAnalytics/Constant'
import './index.scss'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'
import kFormatter, { thousandsSeparator } from 'Utils/kFormatter'
const KeyMetric = ({
  handleDataFunction,
}: {
  handleDataFunction: (filters: FiltersType) => Promise<any>
}) => {
  const columns = [
    {
      title: 'Channels',
      dataIndex: 'channel',
      width: 200,
    },
    {
      title: 'Net Revenue',
      dataIndex: 'sum_sum_revenue',
    },
    {
      title: '% Discount',
      dataIndex: 'discount',
    },
    {
      title: 'Gross Profit',
      dataIndex: 'sum_gross_profit',
    },
    {
      title: '% Gross Profit',
      dataIndex: 'global_ratio_gross_profit',
    },
    {
      title: 'Total Customers',
      dataIndex: 'nunique_customer',
    },
    {
      title: 'New Customer',
      dataIndex: 'new_customer',
    },
    {
      title: 'Return Customer',
      dataIndex: 'return_customer',
    },
    {
      title: 'Orders',
      dataIndex: 'nunique_order',
    },
    {
      title: 'AOV',
      dataIndex: 'aov',
    },
    {
      title: 'Item/Bill (Avg)',
      dataIndex: 'avg',
    },
  ]
  const DataSource = DATA_METRIC_KEY
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const [metrics, setMetrics] = useState<
    {
      channel: string
      sum_sum_revenue: string
      discount: string
      sum_gross_profit: string
      global_ratio_gross_profit: string
      nunique_customer: string
      new_customer: string
      return_customer: string
      nunique_order: string
      aov: string
      avg: string
    }[]
  >([])

  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()

    const handleKeyMetrics = async () => {
      setIsLoading(true)
      try {
        setError('')
        const resp = await handleDataFunction({
          id,
          from_date: queryObject?.from_date?.toString() || defaultFilters.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters.to_date,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          cancelTokenSource,
        })

        let test = resp.data

        test.map((item: any) => {
          return Object.keys(item).map((key, index) => {
            // if (parseFloat(item[key]) != NaN && parseFloat(item[key]) > 100) {
            //   return (item[key] = thousandsSeparator(parseFloat(item[key])))
            // }
            return item[key]
          })
        })

        setMetrics(test)
      } catch (err: any) {
        if (err.message === 'cancelled') {
          return
        }

        setError(err?.response?.data?.detail || 'Some thing went wrong')
      }
      setIsLoading(false)
    }

    handleKeyMetrics()
    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [id, queryObject.from_date, queryObject.to_date, handleDataFunction, queryObject.dimensions])

  if (error) return <Alert message={error} type="error" />

  return (
    <div className="mt-4 ">
      <div className="p-4 bg-white">
        <div className="font-semibold">Key Metric</div>
        <div className="pt-4">
          <Skeleton loading={isLoading} active>
            <TableKeyMetric dataSource={metrics} columns={columns} />
          </Skeleton>
        </div>
      </div>
    </div>
  )
}

export default KeyMetric
