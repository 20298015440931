import { Button } from 'antd'
import React, { useState } from 'react'
import './index.scss'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import kFormatter from 'Utils/kFormatter'
type ChartLineType = {
  lineLabels: { title: string; color: string }[]
  dataSource: any[]
  type?: string
  isHalf?: boolean
}

const ChartLine: React.FC<ChartLineType> = ({ lineLabels, dataSource, type, isHalf }) => {
  const renderLine = () => {
    return lineLabels?.map((item, index) => {
      return (
        <Line key={index} strokeWidth="3" type="linear" dataKey={item.title} stroke={item.color} />
      )
    })
  }

  const renderLegend = (props) => {
    const { payload } = props
    return (
      <div className="flex flex-wrap items-center mt-5 ">
        {payload?.map((entry, index) => (
          <div className="mr-2" key={`item-${index}`}>
            <div className="flex items-center">
              <div>
                <hr style={{ width: 20, borderWidth: '2px', borderColor: entry.color }} />
              </div>
              <div className="ml-1 ">{entry.value}</div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const CustomTooltip = (props) => {
    if (props.active) {
      const { payload } = props
      return (
        <div className="relative text-white bg-primary_text min-w-[76px] min-h-[40px] px-2 py-1 text-left text-sm rounded-sm ">
          <div className="font-bold">{props?.label}</div>
          <div>
            {payload?.map((item, index) => (
              <div key={index}>{`${item.name}: ${kFormatter(parseFloat(item.value))}`}</div>
            ))}
          </div>
        </div>
      )
    } else {
      return (
        <>
          <div className="hidden"></div>
        </>
      )
    }
  }

  const formatTick = (value) => {
    switch (type) {
      case 'discount_ratio':
      case 'gross_profit_ratio':
        return `${value}%`
      default:
        return kFormatter(parseFloat(value))
    }
  }
  const isProduct360 = window.location.href.split('/')[4] === 'product360'

  const renderLineChart = (
    <LineChart data={dataSource} margin={{ right: isProduct360 ? 32 : 0, bottom: 25 }}>
      {renderLine()}
      <CartesianGrid stroke="#ccc" />
      <XAxis
        dataKey="name"
        scale="band"
        interval={0}
        angle={isHalf && dataSource?.length >= 4 ? -45 : dataSource?.length >= 15 ? -45 : 0}
        textAnchor="end"
      />
      <YAxis tickFormatter={formatTick} />
      <Tooltip content={<CustomTooltip />} />
      <Legend content={renderLegend} wrapperStyle={{ marginBottom: -25 }} />
    </LineChart>
  )

  return (
    <div className=" LineChartReChartCustom">
      <ResponsiveContainer width="100%" height={300}>
        {renderLineChart}
      </ResponsiveContainer>
    </div>
  )
}

export default ChartLine
