import React from 'react'

import BarChartTemplate from '../../Components/BarChart.Template'
import MultiDoughnutChartTemplate from '../../Components/MultiDoughnutChart.Template'

import { getSegment360Charts } from 'Services/Analytics/segment-analytics'

const ChartRow2 = () => {
  return (
    <>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 font-semibold">Customer Life Cycle (CLC)</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <MultiDoughnutChartTemplate
            chartCode="customer_life_cycle"
            handleDataFunction={getSegment360Charts}
          />
        </div>
      </div>
      <div className="p-4 col-span-2 shadow-div_b">
        <div className="mb-4 font-semibold">CLC</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="clc_by_time"
            handleDataFunction={getSegment360Charts}
            isStacked
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_r">
        <div className="mb-4 font-semibold">CLC X LTV</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="clc_x_ltv"
            handleDataFunction={getSegment360Charts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_r">
        <div className="mb-4 font-semibold">CLC x Cluster</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="clc_x_cluster"
            handleDataFunction={getSegment360Charts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
      <div className="p-4 col-span-1">
        <div className="mb-4 font-semibold">CLC x Sale Channel</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="clc_x_sale_channel"
            handleDataFunction={getSegment360Charts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow2
